import { makeStore, createReducerFromObject } from '../../utils';
import { AccessGraphState, initialState } from './state'
import { accessGraphObjectReducer, ACCESS_GRAPH_ACTIONS } from './reducer';

const [AccessGraphProvider, useAccessGraphDispatch, useAccessGraphState] = makeStore<AccessGraphState>(createReducerFromObject(accessGraphObjectReducer), initialState);

function useAccessGraphSetters () {
  const dispatch = useAccessGraphDispatch();

  const setAccessGraph = (accessGraph: any) => {
    dispatch({
      type: ACCESS_GRAPH_ACTIONS.SET_ACCESS_GRAPH,
      accessGraph
    })
  }

  return { setAccessGraph }
}

export { AccessGraphProvider, useAccessGraphSetters, useAccessGraphState };