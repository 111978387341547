import * as React from 'react';
import Icon from 'components/@shared/icons';
import Loading from 'components/@shared/loading-indicator';
import './all.scss';

const { PureComponent, Fragment } = React;

interface Props {
  className?: string;
  controls?: any;
  disabled?: boolean;
  icon?: any;
  iconLocation?: string;
  id?: any;
  label?: string;
  loading?: Array<any>;
  onClick?: (e?: any) => any;
  role?: any;
  selected?: any;
  size?: any;
  state?: any;
  theme?: any;
  type?: any;
  isSubmit?: boolean;
}

interface State {
  label?: string;
}

class Button extends PureComponent<Props, State> {
  newProps?: any;

  UNSAFE_componentWillMount() {
    const { label } = this.props;
    this.setState({
      label,
    });
  }

  renderLoading() {
    return this ? (
      <Fragment>
        <Loading />
      </Fragment>
    ) : null;
  }

  renderIconBefore() {
    const { icon, children } = this.props;
    return (
      <Fragment>
        <Icon name={icon} />
        <span>{children}</span>
      </Fragment>
    );
  }

  renderIconAfter() {
    const { icon, children } = this.props;
    return (
      <Fragment>
        <span>{children}</span>
        <Icon name={icon} />
      </Fragment>
    );
  }

  renderNoIcon() {
    const { children } = this.props;
    return (
      <Fragment>
        <span>{children}</span>
      </Fragment>
    );
  }

  render() {
    const { isSubmit, icon, iconLocation, theme, ...newProps } = this.props;
    const { label } = this.state;
    const classes = [
      'button',
      `button-${newProps.type}`,
      `button-${newProps.size}`,
      `${newProps.disabled ? 'button-disabled' : ''}`,
      `${newProps.className || ''}`,
    ].join(' ');

    const loading = !!(
      newProps.loading && newProps.loading.indexOf(newProps.id) > -1
    );

    const buttonType = isSubmit ? 'submit' : 'button';
    return (
      <button
        type={buttonType}
        id={newProps.id}
        title={label}
        role={newProps.role ? newProps.role : 'button'}
        aria-controls={newProps.controls}
        aria-label={label}
        className={classes}
        data-open={newProps.state}
        data-theme={theme}
        data-cy={newProps.id}
        onClick={newProps.onClick}
        disabled={loading ? true : !!newProps.disabled}
        aria-selected={newProps.selected}
        aria-live="assertive"
        {...this.newProps}
      >
        {loading && this.renderLoading()}
        {!loading && !icon && this.renderNoIcon()}
        {!loading &&
          icon &&
          iconLocation === 'before' &&
          this.renderIconBefore()}
        {!loading && icon && iconLocation === 'after' && this.renderIconAfter()}
        {!loading && icon && !iconLocation && this.renderIconAfter()}
      </button>
    );
  }
}
export default Button;
