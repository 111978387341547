import { observable, action } from 'mobx';

export class TabStoreBlueprint {
  @observable tabToShow: number = 0;

  @action
  updateActiveTab = (index: number) => this.tabToShow = index

}

const TabStore = new TabStoreBlueprint();
export default TabStore;
