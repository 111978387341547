import { action, observable, computed } from 'mobx';

import DataHandlerStore from './DataHandlerStore';
import { convertToTable } from '@helpers/util';
import ROUTES from '@constants/routes';
import BaseStore from './BaseStore';
import UserStore from './UserStore';
import { StudyModelUpdate } from './@StoreModels';
import {candidateEndpoint, clientsEndpoint, studyEndpoint} from '@helpers/config';
import { ROLE_TPDP } from '@helpers/RoleTypes';
import SitesStore from './SitesStore';
import DataTableStore from './DataTableStore';

export class StudyStoreBlueprint extends BaseStore {
    @observable data: any = [];
    @observable studyOnSelect: any = [];
    @observable dataAsTable: any = [];
    @observable clients: any = [];
    @observable populatedStudies: any = [];
    @observable callCentreChanged: boolean = false;
    @observable updatedFilters: any = [];
    @observable filterObject: any = {};

    @action
    resetFilters = async () => {
        DataTableStore.resetSelectedPage();
        DataTableStore.resetSelectedFilter();
        await this.getStudies();
        DataTableStore.data = this.dataAsTable;
    }

    @action
    filterBy = async (type, filterValue: string) => {
        DataTableStore.resetSelectedPage();
        if (!type || !filterValue) {
            await this.resetFilters()
            return
        }
        this.filterObject[type] = filterValue;
        await this.load('', this.filterObject);
        DataTableStore.data = this.dataAsTable;
    }

    @action
    applyFilterEnabled = async (filter: any) => {
        const filterKeys: Array<string> = Object.keys(filter);
            if (filterKeys.includes('enabled')) {
                await this.getStudies();
                switch (filter.enabled) {
                    case 'Active':
                        this.data = this.data.filter(obj => {
                            return obj.enabled.value === 'Active'
                        })
                        this.dataAsTable = convertToTable(this.data)
                        return;
                    case 'Deactivated':
                        this.data = this.data.filter(obj => {
                            return obj.enabled.value === 'Deactivated'
                        })
                        this.dataAsTable = convertToTable(this.data)
                        return;
                    default:
                        break;
                }
            }
    }

    @action
    load = async (id: string, filter: any = {}) => {
        if (Object.keys(filter).length !== 0) {
            await this.applyFilterEnabled(filter);
            return;
        }
        if (id) {
            this.current = {};
            await this.getClients();
            await this.getStudy(id);
            return;
        }
        await this.getStudies();
    };

    @action
    resetOnSelect = () => {
        this.studyOnSelect = [];
    }

    @action
    create = async (body: any) => {
        const response: any = await DataHandlerStore.post(studyEndpoint.createStudy.name, body);
        this.current = response.data;
        this.data = [];
        return Promise.resolve(response);
    };

    @action
    getNew = async () => {
        const { data }: any = await DataHandlerStore.get(studyEndpoint.getNewStudy.name);
        this.current = data;
    };

    @action
    getStudies = async () => {
        const response: any = await DataHandlerStore.get(studyEndpoint.getStudies.name);
        const { data } = response;
        this.data = data;
        this.dataAsTable = convertToTable(data);
        return Promise.resolve(response);
    };

    @action
    getStudy = async (id: string) => {
        const response: any = await DataHandlerStore.get(studyEndpoint.getStudyById.name, id);
        this.current = response.data;
        return Promise.resolve(response);
    };

    // @action
    // getStatuses = async (studyId) => {
    //     const response: any = await DataHandlerStore.get(candidateEndpoint.getStatusList.name, studyId);
    //     const { data } = response;
    //     this.statuses = data;
    //     return Promise.resolve(data);
    // };
    //
    @action
    getByClientId = async (id: any) => {
        this.resetOnSelect();
        let clientId = id;

        if (typeof id === 'object') {
            clientId = id.value;
            UserStore.current.clientId = id;
        }
        if (Array.isArray(clientId)) {
            clientId = clientId[0];
        }

        const response: any = await DataHandlerStore.get(studyEndpoint.getByClientId.name, clientId);
        this.data = response.data;
        this.studyOnSelect = response.data;
        const userCurrent = UserStore.current;

        if (userCurrent && userCurrent.callCentreId && userCurrent.callCentreId.value) {
            StudyStore.filterStudyByCallCentreId({ id: userCurrent.callCentreId.value });
        }

        return Promise.resolve(response);
    };

    @action
    getSitesForStudyId = async (id: string) => {
        const response = await DataHandlerStore.get(studyEndpoint.getSitesForStudyId.name, id);
        return Promise.resolve(response);
    };

    @action
    getClients = async () => {
        const response: any = await DataHandlerStore.get(clientsEndpoint.getClients.name);
        this.clients = response.data;
        this.getAccess(UserStore);
        return Promise.resolve(response);
    };

    @action
    navigateEdit = (history: any, id: string) => {
        this.navigate(history, ROUTES.STUDIES.constsructEdit(id));
    };

    @action
    addPopulatedStudy = async (value) => {
        const response = await this.getStudy(value);
        this.populatedStudies.push(response.data.name.value);
    }

    @action
    removePopulatedStudy = async (value) => {
        this.populatedStudies = this.populatedStudies.filter(item => {
            return item !== value;
        });
    }

    @action
    remove = async (id: string) => {
        const response: any = await DataHandlerStore.delete(studyEndpoint.deleteStudy.name, id);
        this.data = [];
        return Promise.resolve(response);
    };

    @action
    update = async (body: StudyModelUpdate) => {
        const response = await DataHandlerStore.post(studyEndpoint.updateStudy.name, body);
        this.data = [];
        return Promise.resolve(response);
    };

    @action
    saveStudyToSelection = (studyId) => {
        if (this.alreadyInSiteAccess(studyId)) {
            const curr = UserStore.siteAccess.filter(item => item.relatedId === studyId);
            UserStore.siteAccess.remove(curr[0])
        }

        UserStore.siteAccess.push({ relatedId: studyId });
    };

    @action
    filterStudyByCallCentreId = ({ id, studies = this.studyOnSelect }) => {
        if (!id) {
            throw new TypeError('Param must have an id');
        }

        const containsCallCentreIdInPayload = studies.some(item => item.callCentreId);
        if (containsCallCentreIdInPayload) {
            const filtered = studies.filter(item => item.callCentreId && item.callCentreId.value === id);
            studies = filtered;
            this.studyOnSelect = filtered;
            this.data = filtered;
        }

        return studies;
    }

    @action
    filterAvailableStudies = data => {
        this.populatedStudies = [...UserStore.siteAccess.map(site => site.studyName)];
        if(data) {
            const filteredStudies = data.filter(study => {
                return !this.populatedStudies.includes(study.display);
            });
            let updatedFilters = filteredStudies;
            return updatedFilters;
        }
    }

    @action
    onCallCentreChanged = async (selectedId) => {
        if(!selectedId) {
            return
        }

        const { clientId } = UserStore.current;
        const {data} =  await this.getByClientId(clientId);

        this.filterStudyByCallCentreId({id: selectedId, studies: data})
        this.callCentreChanged = true;
    };

    @computed
    get canOnlyAddStudies() {
        return UserStore.current.role.value === ROLE_TPDP;
    }

    @computed
    get canViewAddStudies() {
        const hasUnselectedStudies = UserStore.siteAccess && UserStore.siteAccess.length < this.studyOnSelect && this.studyOnSelect.length;
        return !this.canOnlyAddStudies && SitesStore.canAddNewSites && hasUnselectedStudies;
    }

    @computed
    get canViewComputedSection() {
        return !this.canOnlyAddStudies && UserStore.siteAccess && UserStore.siteAccess.length > 0;
    }

    @computed
    get canViewStudyDropdown() {
        const disabledSitesOrStudies = !SitesStore.canAddNewSites || this.canOnlyAddStudies;
        const hasStudies = this.studyOnSelect && this.studyOnSelect.length > 0;
        return disabledSitesOrStudies && hasStudies;
    }

    alreadyInSiteAccess = (key: string) => UserStore.siteAccess.some(access => access.relatedId === key);
}

const StudyStore = new StudyStoreBlueprint();
export default StudyStore;
