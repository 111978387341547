const admins = '/admins';
const clients = '/clients';
const sites = '/sites';
const candidates = '/candidates';
const studies = '/studies';
const users = '/users';
const callCentres = '/call-centres';

const construct = (index: string) => {
    return {
        index,
        edit: `${index}/edit/`,
        create: `${index}/create`,
        constsructEdit: (id: string) => { return `${index}/edit/${id}`; },
    };
};

export const CLIENTS = construct(clients);
export const SITES = construct(sites);
export const CANDIDADTES = construct(candidates);
export const STUDIES = construct(studies);
export const USERS = construct(users);
export const CALLCENTRES = construct(callCentres);
export const ADMINS = construct(admins);

const ROUTES = {
    ADMINS,
    CLIENTS,
    SITES,
    CANDIDADTES,
    STUDIES,
    USERS,
    CALLCENTRES,
};

export const PUBLIC_ROUTES = [
    'login',
    'reset-password',
    'set-flaring'
];

export default ROUTES;
