import { observable, action, flow } from 'mobx';
import axios from 'axios';
import Config from '@helpers/config';
import { sanitiseData, selectOptionNoValue } from '@helpers/util';

export class DataHandlerStoreBlueprint {
    @observable fetchingContent: boolean = false;
    @observable renderContent: boolean = false;
    @observable error: any;
    @observable showError: boolean = false;
    @observable errorMessage: any;
    dispatch: any;

    setDispatch = (func) => {
        this.dispatch = func;
    }

    @action.bound
    fetch = flow(function* (this: any, data: any) {
        this.interceptor();
        if (data && data.id === selectOptionNoValue) {
            return Promise.resolve(true);
        }
        try {
            this.showError = false;
            const axiosData = this.setAxiosData(data);
            const response: any = yield axios(axiosData);
            return Promise.resolve(response);
        } catch (error) {
            this.error = error;
            this.errorMessage = this.getError(error);
            this.updateShowError(true);
            const errValue = error as any;
            if (errValue.response && errValue.status === 401) {
                localStorage.clear();
            }

            this.dispatch({
                type: 'ERROR',
                errors: Object.values(this.errorMessage).map(error => {
                    if (typeof error === 'object') {
                        return false;
                    }

                    return error;
                }).filter(error => !!error)
            });

            return Promise.reject(error);
        }
    },
    );

    getError = (error) => {
        const defaultMessage = 'Server error: Contact your system admin';
        if (!error.response || !error.response.data) {
            return error;
        }
        if (error && error.response && error.response.data && error.response.data.error) {
            const { response: { data: { error: { validationErrors } } } } = error;
            if (error.response.data.response) {
                const { response: { data: { response: { validationErrors: innerValidation } } } } = error;
                if (innerValidation) {
                    return innerValidation;
                }
            }
            if (validationErrors) {
                return validationErrors;
            }
            if (error.response.status === 500) {
                return [defaultMessage];
            }
            return [error.response.data.error];
        }
        if (error && error.response && error.response.data) {
            return error.response.data;
        }
        if ((Object.entries(error).length === 0 && error.constructor === Object)) {

            return [defaultMessage];
        }

        return error;
    };

    @action
    post = (action: string, body: any, params: any = []) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response: any = await this.fetch({
                    action,
                    body,
                    params
                });
                return resolve(response.data);
            } catch (error) {
                this.error = true;
                reject(error);
            }
        });
    };

    @action
    delete = (action: string, id: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response: any = await this.fetch({
                    action,
                    body: { id },
                });
                return resolve(response.data);
            } catch (error) {
                reject(error);
            }
        });
    };

    @action
    get = (action: string, id: any = null, options: object = {}) => {
        return new Promise(async (resolve, reject) => {
            try {
                let body = {
                    action,
                    id
                };
                if (!id) {
                    delete body.id;
                }
                if (Object.keys(options).length > 0) {
                    body['options'] = options
                }
                const response: any = await this.fetch(body);
                return resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    };

    @action
    getAsTable = async (action: string, id: any = null) => {
        const response: any = await this.get(action, id);
        const sanitized = sanitiseData(response.data);
        return Promise.resolve(sanitized);
    };

    @action
    updateShowError = (status: boolean) => {
        this.showError = status;
    };

    setAxiosData = (data: any) => {
        const { action, body, id, options, params } = data;
        const base = {
            data: {},
            url: '',
            headers: {
                'Content-Type': 'application/json',
                authorization: '',
            },
            ...options,
        };
        if (!action) {
            return base;
        }
        if (body && body.isdownloadZip) {
            base.headers.Accept = 'application/zip';
            base.responseType = 'arraybuffer';
            delete body.isdownloadZip;
        }

        let endpointUrl: string = Config.apiEndpoint[action].endpoint;
        base.url = `${Config.apiEndpoint.base + endpointUrl}`;
        if (id) {
            base.url += id;
        }
        if (params && params.length) {
            base.url += params.join('/');
        }
        const token = localStorage.getItem('token');
        if (token && token.length) {
            base.headers.authorization = `Bearer ${token}`;
        }
        if (body) {
            base.data = body;
        }
        return {
            ...base,
            method: Config.apiEndpoint[action].method,
        };
    }

    loadingContent = () => {
        this.fetchingContent = true;
    }

    contentLoaded = () => {
        this.fetchingContent = false;
        this.renderContent = true;
    }

    interceptor = () => {
        let numberOfAjaxCAllPending = 0;
        axios.interceptors.request.use((config) => {
            numberOfAjaxCAllPending++;
            if (config.url && !config.url.includes('heartbeat')) {
                this.loadingContent();
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        // Add a response interceptor
        axios.interceptors.response.use((response) => {
            numberOfAjaxCAllPending--;
            if (numberOfAjaxCAllPending === 0) {
                this.contentLoaded();
            }
            return response;
        }, (error) => {
            numberOfAjaxCAllPending--;
            this.contentLoaded();
            return Promise.reject(error);
        });
    }
}
const DataHandlerStore = new DataHandlerStoreBlueprint()
export default DataHandlerStore;
